import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';

import SEO from '../components/seo';
import Subheader from '../components/subheader';
import { Layout } from '../containers/layout';
import { Modal } from '../containers/modal';
import { LeistungenTemplate } from '../pageTemplates/leistungen';
import { GraphQLData } from '../types';
import { useStore } from '../zustand';

interface LeistungenProps {
  data: GraphQLData;
  type: string;
  location: any;
  pageContext: any;
}

const Services = ({ data, location, pageContext }: LeistungenProps) => {
  const { type } = pageContext;
  const discipline = data?.strapi?.discipline[0] || {};
  const method = data?.strapi?.method[0] || {};
  const { subheader, meta } = data.strapi?.praxi || {};
  const { title = 'Praxis', description } = meta ?? {};

  // change modal
  const [setModalLevel1Visible, setModalLevel1Data] = useStore(
    (state) => [state.setModalLevel1Visible, state.setModalLevel1Data],
    shallow
  );
  const changeModal = ({ id, ...rest }) => {
    setModalLevel1Data({
      name: type,
      id,
      ...rest,
    });
    setModalLevel1Visible(true);
  };

  useEffect(() => {
    changeModal(type === 'method' ? method : discipline);
  }, []);

  return (
    <Layout>
      <SEO description={description} title={title} />
      <Subheader intro={subheader} />
      <LeistungenTemplate data={data} location={location} />
      <Modal services />
      <Modal team level="2" />
    </Layout>
  );
};

export const query = graphql`
  query ServicesTemplate($slug: String!) {
    strapi {
      discipline: disciplines(where: { slug: $slug }) {
        id
      }
      method: methods(where: { slug: $slug }) {
        id
      }
      service {
        meta {
          title
          description
        }
        subheader
        disciplineTitle
        disciplineSubtitle
        methodsTitle
        methodsSubtitle
        diseaseTitle
        competencyCover {
          url
          file {
            ...Image
          }
        }
        methodCover {
          url
          file {
            ...Image
          }
        }
        diseaseInformation
        diseases {
          title
        }
      }
      disciplines(sort: "order:ASC") {
        id
        link
        cover {
          url
          file {
            ...Image
          }
        }
        content {
          ... on STRAPI_ComponentArticleImage {
            id
            image {
              url
              file {
                ...Image
              }
            }
          }
          ... on STRAPI_ComponentArticleText {
            id
            text
          }
        }
        teaser
        title
      }
      methods(sort: "order:ASC") {
        id
        link
        id
        teaser
        title
        cover {
          url
          file {
            ...Image
          }
        }
      }
    }
  }
`;

export default Services;
